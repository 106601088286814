<template>
  <b-container fluid>
    <b-modal id="history" title="Vēsture">
      <div class="accordion" role="tablist" v-if="history && history.length > 0">
        <div class="mb-1" v-for="(row, index) in history">
            <b-button v-b-toggle="'accordion-'+index" class="m-1">{{ getUserById(row.user_id).name }} {{ getUserById(row.user_id).surname }} -
              {{ new Date(row.created_at).toLocaleDateString() }} {{ new Date(row.created_at).toLocaleTimeString() }}</b-button>
          <b-collapse :id="'accordion-'+index" accordion="my-accordion" role="tabpanel">
            <b-card-body v-if="translations[row.key] && !(parseInt(row.new_value) > 1 || parseInt(row.old_value) > 1)">
              Tika mainīts lauks: {{ translations[row.key] }}<br>
              {{ row.old_value || 'nav' }} → {{ row.new_value }}
            </b-card-body>
            <b-card-body v-else-if="translations[row.key] === 'izveidots'">
              Tika izveidots sākotnējais ieraksts
            </b-card-body>
            <b-card-body v-else>
              Tika mainīts lauks: {{ translations[row.key] }}<br>
            </b-card-body>
          </b-collapse>
        </div>
      </div>
      <div v-else>Nav ierakstu vēsturē</div>
    </b-modal>
    <b-row>
      <b-col cols="12">
        <b-card
            header="Lietotāji"
            header-tag="header"
            footer-tag="footer"
            footer=" "
        >
          <b-button variant="success" class="float-right" :to="{ name: 'UserCreate' }" v-if="User.isAdmin">Pievienot</b-button>

          <b-col cols="12" class="text-center" v-if="!loaded">
            <b-spinner variant="success" label="Spinning"></b-spinner>
          </b-col>

          <b-alert
              :show="dismissCountDown"
              v-if="created == 'success'"
              dismissible
              variant="success"
              @dismissed="dismissCountDown=0"
              @dismiss-count-down="countDownChanged"
          >
            <p>Darbinieks tika veiksmīgi arhivēts!</p>
            <b-progress
                variant="success"
                :max="dismissSecs"
                :value="dismissCountDown"
                height="4px"
            ></b-progress>
          </b-alert>

          <b-alert
              :show="dismissCountDown"
              v-if="created == 'error'"
              dismissible
              variant="danger"
              @dismissed="dismissCountDown=0"
              @dismiss-count-down="countDownChanged"
          >
            <p>Ups! Notika kļūda</p>
            <b-progress
                variant="danger"
                :max="dismissSecs"
                :value="dismissCountDown"
                height="4px"
            ></b-progress>
          </b-alert>

          <b-row>
            <b-col lg="9" class="my-1">
              <b-form-group
                  class="m-1"
              >
                <b-input-group size="sm">
                  <b-form-input
                      id="filter-input"
                      v-model="searchInput"
                      :debounce="300"
                      type="search"
                      placeholder="Meklēt pēc vārda, uzvārda"
                      v-if="User.isAdmin"
                  ></b-form-input>

                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>

          <b-table striped hover responsive="true" small v-b-hover fixed
                   ref="table"
                   :items="items"
                   :fields="fields"
                   :no-provider-sorting="true"
                   :no-local-sorting="true"
                   @sort-changed="sortingChanged"
                   v-if="loaded">

            <template #cell(roles)="row">
              <span> {{ getRoleNames(row.item.roles) }} </span>
            </template>

            <template #cell(actions)="row">
              <b-button size="sm" variant="success" @click="$router.push({ name: 'UserView', params: {id: row.item.id} })" class="mr-1">
                Skatīt
              </b-button>
              <b-button size="sm" variant="warning" v-if="User.isAdmin" @click="$router.push({ name: 'UserEdit', params: {id: row.item.id} })" class="mr-1">
                Labot
              </b-button>
              <b-button v-if="User.isAdmin" size="sm" variant="danger" v-b-modal.modal-sm @click="deleteItem(row.item.id, row.index)" class="mr-1">
                Arhivēt
              </b-button>
              <b-button v-if="User.isAdmin" size="sm" variant="secondary"  v-b-modal.history @click="history = row.item.history">
                Vēsture
              </b-button>
            </template>

          </b-table>
          <template v-if="isBusy && !noData">
            <div class="text-center text-success my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong> Dati tiek lejupielādēti...</strong>
            </div>
          </template>

        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "List",
  data() {
    return {
      documents: null,
      created: null,
      dismissSecs: 5,
      dismissCountDown: 0,
      currentPage: 1,
      noData: false,
      datePicker: {
        dateStart: null,
        dateEnd: null,
      },
      search: null,
      sort: null,
      isBusy: false,
      items: [],
      selectType: 0,
      searchString: '',
      fields: [
        { key: 'name', label: 'Vārds', sortable: true },
        { key: 'surname', label: 'Uzvārds', sortable: true },
        { key: 'personal_code', label: 'Pers. kods' },
        { key: 'roles', label: 'Lomas' },
        { key: 'actions', label: 'Darbības' },
      ],
      loaded: true,
      history: null,
      users: [],
      translations: {
        'name': 'vārds',
        'surname': 'uzvārds',
        'personal_code': 'personas kods',
        'email': 'e-pasts',
        'phone': 'tālrunis',
        'gender': 'dzimums',
        'education_type': 'izglītības veids',
        'grade': 'grāds',
        'qual': 'kvalifikācija',
        'edu': 'audzināšanas stundas',
        'rights': 'bērnu tiesības',
        'courses': 'pedagoģiskā izglītība',
        'short_courses': 'kursi 36h',
        'category': 'kategorija',
        'status': 'status',
        'agreement': 'līguma nr.',
        'agreement_date': 'līguma datums',
        'comment': 'koments',
        'archived': 'arhivēts',
        'birthdate': 'dzimšanas datums',
        'created_at': 'izveidots'
      }
    }
  },
  async mounted() {
    this.getData();


    await axios.get('/users').then((response) => {
      this.users = response.data.data
    })

    window.onscroll = () => {
      let bottomOfWindow = document.documentElement.scrollHeight - document.documentElement.scrollTop - document.documentElement.clientHeight <= 0;
      if (bottomOfWindow && !this.isBusy) {
        this.getData();
      }
    };
  },
  computed: {
    ...mapGetters ({
      User: "Auth/user",
    }),
    searchInput: {
      get() {
        return this.search;
      },
      set(value) {
        this.search = value;
        this.onFilter();
      }
    },
    selectedDateType: {
      get() {
        return this.selectType;
      },
      set(value) {
        this.selectType = value;
        this.onFilter();
      }
    },
    dateStart: {
      get() {
        return this.datePicker.dateStart;
      },
      set(value) {
        this.datePicker.dateStart = value;
        this.onFilter();
      }
    },
    dateEnd: {
      get() {
        return this.datePicker.dateEnd;
      },
      set(value) {
        this.datePicker.dateEnd = value;
        this.onFilter();
      }
    }
  },
  methods: {
    getUserById(id) {
      return this.users.filter((user) => {
        if(user.id === id) {
          return user
        }
      })[0]
    },
    getRoleNames(roles) {
      let names = "";
      roles.forEach((e, i) => {
        if(i != roles.length-1) {
          names += e+', ';
        } else {
          names += e;
        }
      })

      return names;
    },
    deleteItem(id, index) {
      axios.delete('/users/'+id).then(response => {

        this.items.splice(index, 1);

        this.created = 'success';
      }).catch(e => {
        this.created = 'error';
      })

      this.dismissCountDown = 5;
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },
    async getData() {
      this.isBusy = true;
      await axios.get('/users?page[number]=' +
          this.currentPage +
          (this.sort ? '&sort=' + (this.sort.sortDesc ? '-' : '')+this.sort.field : '') +
          this.searchString
      ).then(response => {

        let data = response.data.data;

        if(data.length) {
          this.items = this.items.concat(data);
          this.noData = false;
          this.isBusy = false;
        } else {
          this.noData = true;
        }
      }).catch(e => {
        this.isBusy = false;
      })

      this.currentPage++;
    },
    sortingChanged(ctx) {

      this.sort = {
        'field': ctx.sortBy,
        'sortDesc': ctx.sortDesc,
      }

      this.currentPage = 1;
      this.items = [];
      this.getData();
    },
    onFilter() {
      this.currentPage = 1;
      this.items = [];

      this.searchString = "";

      if(this.search)
        this.searchString += '&filter[name]='+this.search;

      this.getData();
    },
  },
}
</script>

<style scoped>

</style>